import React from 'react';
import { Link } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import styles from './SecondScreen.module.scss';
import email from '../../images/Social-Email-Blue.svg';
import fb from '../../images/Social-Facebook-Blue.svg';
import linkedin from '../../images/linkedin.svg';
import tw from '../../images/Social-Twitter-Blue.svg';

export default class SecondScreen extends React.Component<any> {
  state;

  constructor(props) {
    super(props);

    this.state = {
      page: 1,
    };
  }

  get relatedPosts() {
    let posts = [...this.props.posts]
      .filter((p) => p.node.frontmatter.category === this.props.post.frontmatter.category)
      .filter((p) => p.node.id !== this.props.post.id);

    if (posts.length < 3 || posts.length < 3 * this.state.page) {
      posts = [
        ...posts,
        ...this.props.posts.filter(
          (p) => p.node.frontmatter.category !== this.props.post.frontmatter.category,
        ),
      ];
    }

    return posts.slice(0, 3 * this.state.page);
  }

  render() {
    const { post, location } = this.props;

    return (
      <section className={styles.screenBox} ref={this.props.setRef}>
        <div className={styles.articleBox} id="article">
          <div dangerouslySetInnerHTML={{ __html: post.html }} className={styles.content}></div>
          <svg
            id="Logo_Mark_White"
            data-name="Logo/Mark/White"
            width="2vw"
            height="2vw"
            viewBox="0 0 33 35"
          >
            <g id="Click_logo-mark" transform="translate(0.254)">
              <path
                id="Path"
                d="M20.777,27.295a4.816,4.816,0,0,1-2.394-.627l-.16-.078a.477.477,0,0,1-.186-.131l-.106-.078a30.625,30.625,0,0,0-3.857-2.586,27.894,27.894,0,0,0-4.23-1.985l-.133-.052a.8.8,0,0,1-.213-.1l-.133-.078L9.231,21.5a4.792,4.792,0,0,1,0-8.358l.053-.026h0l.133-.078c.053-.026.133-.052.186-.078l.133-.052a30.665,30.665,0,0,0,4.23-1.985,29.233,29.233,0,0,0,3.884-2.612l.133-.1c.053-.052.133-.078.186-.131l.106-.052H18.3a5.041,5.041,0,0,1,2.447-.653,4.866,4.866,0,0,1,4.523,2.978,1.221,1.221,0,0,0,1.7.575c1.463-.81,3.591-1.985,4.895-2.69a1.2,1.2,0,0,0,.372-1.776,17.469,17.469,0,0,0-6.757-4.937h0l-.16-.078A19.139,19.139,0,0,0,17.665,0a17.766,17.766,0,0,0-12.5,4.937A17.153,17.153,0,0,0,0,17.4V17.6A17.153,17.153,0,0,0,5.161,30.063,17.766,17.766,0,0,0,17.665,35a19.139,19.139,0,0,0,7.662-1.437l.16-.078h0a17.862,17.862,0,0,0,6.757-4.937,1.2,1.2,0,0,0-.372-1.776c-1.33-.731-3.538-1.959-5.028-2.769a1.236,1.236,0,0,0-1.7.522A4.706,4.706,0,0,1,20.777,27.295Z"
                fill="#5E91FF"
              />
              <path
                id="Path-2"
                data-name="Path"
                d="M12.185,1.851A1.82,1.82,0,0,0,10.379,0a1.726,1.726,0,0,0-.9.257l-.05.026h0A30.677,30.677,0,0,1,5.39,3.136,30.208,30.208,0,0,1,.953,5.295h0L.9,5.321a1.858,1.858,0,0,0,0,3.187l.125.077h0a30.208,30.208,0,0,1,4.438,2.159A33.245,33.245,0,0,1,9.5,13.571h0a1.78,1.78,0,0,0,2.658-1.594v-.129a31.059,31.059,0,0,1-.376-5.012,31.094,31.094,0,0,1,.4-4.986Z"
                transform="translate(10.154 10.352)"
                fill="#5E91FF"
              />
            </g>
          </svg>
          <div className={styles.grayTriangle}></div>
        </div>

        <div className={styles.rightBox}>
          <div className={styles.shareBox}>
            <span className={styles.boxHeading}>share this post</span>
            <div>
              <a
                target="_blank"
                rel="noreferrer"
                href={`mailto:?subject=${post.frontmatter.title}&body=${location.origin}${location.pathname}`}
              >
                <img src={email} alt="email"></img>
              </a>
              {location.host ? (
                <a
                  href={`https://www.facebook.com/sharer/sharer.php?u=${location.host}${location.pathname}`}
                  rel="noreferrer"
                  target="_blank"
                >
                  <img src={fb} alt="facebook"></img>
                </a>
              ) : null}
              {location.host ? (
                <a
                  href={`https://www.linkedin.com/shareArticle?url=${location.host}${location.pathname}`}
                  rel="noreferrer"
                  target="_blank"
                >
                  <img src={linkedin} alt="linkedin"></img>
                </a>
              ) : null}
              {location.origin ? (
                <a
                  href={`https://twitter.com/intent/tweet?url=${location.origin}${location.pathname}`}
                  rel="noreferrer"
                  target="_blank"
                >
                  <img src={tw} alt="twitter"></img>
                </a>
              ) : null}
            </div>
          </div>
          <div className={styles.relatedPostsBox}>
            <span className={styles.boxHeading}>related posts</span>
            {this.relatedPosts.map((post, i) => (
              <Link to={post.node.fields.slug} className={styles.article} key={i}>
                <BackgroundImage
                  className={styles.desktopView}
                  Tag="div"
                  fluid={post.node.frontmatter.mainImage.childImageSharp.fluid}
                >
                  <div className={styles.topText}>
                    <div className={styles.articleTitle}>{post.node.frontmatter.title}</div>
                    <span className={styles.readMore}>READ MORE</span>
                  </div>
                  <div className={styles.bottomText}>
                    <p>{post.node.frontmatter.category}</p>
                    <p>{post.node.frontmatter.date}</p>
                  </div>
                </BackgroundImage>

                <div className={styles.mobileView}>
                  <div className={styles.postBox}>
                    <BackgroundImage
                      className={styles.postPhoto}
                      Tag="div"
                      fluid={post.node.frontmatter.mainImage.childImageSharp.fluid}
                    >
                      <span className={styles.postTitle}>{post.node.frontmatter.title}</span>
                    </BackgroundImage>
                    <p className={styles.postExcerpt}>{post.node.excerpt}</p>
                    <span className={styles.readMore}>READ MORE</span>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
        <div
          style={{
            opacity: this.relatedPosts.length + 1 === this.props.posts.length ? 0.5 : 1,
          }}
          className={styles.seeMore}
          onClick={() => this.setState({ page: this.state.page + 1 })}
        >
          SEE MORE
        </div>
      </section>
    );
  }
}

import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import Layout from 'components/layouts/main/Layout';
import FirstScreen from '../components/post/FirstScreen';
import SecondScreen from '../components/post/SecondScreen';

import { gatsbyWebsiteBaseUrl } from 'services/config';

export default class Post extends React.Component<any> {
  articleRef: any;

  constructor(props: any) {
    super(props);
    this.articleRef = React.createRef();
  }

  render() {
    const { data } = this.props;
    const post = data.markdownRemark;
    const withoutDummyPost = data.allMarkdownRemark.edges
      .filter((p: any) => p.node.frontmatter.title !== 'dummy_post')
      .filter((p: any) => p.node.fields.slug.indexOf('/blog/') === 0);

    return (
      <Layout location={this.props.path}>
        <Helmet>
          <title>{post.frontmatter.title}</title>
          <meta name="description" content={post.excerpt} />
          <meta
            property="og:image"
            content={post.frontmatter.mainImage?.childImageSharp.fluid.src}
          />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={post.frontmatter.title} />
          <meta name="twitter:description" content={post.excerpt} />
          <meta
            name="twitter:image"
            content={gatsbyWebsiteBaseUrl + post.frontmatter.mainImage?.childImageSharp.fluid.src}
          />
        </Helmet>
        <FirstScreen
          post={post}
          location={this.props.location}
          articleRef={this.articleRef}
        ></FirstScreen>
        <SecondScreen
          location={this.props.location}
          post={post}
          posts={withoutDummyPost}
          setRef={this.articleRef}
        ></SecondScreen>
      </Layout>
    );
  }
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      id
      excerpt
      frontmatter {
        title
        date
        author
        category
        mainImage {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allMarkdownRemark {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD.MM.YY")
            author
            category
            mainImage {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`;

import React from 'react';
import { format } from 'date-fns';

import styles from './FirstScreen.module.scss';

export default ({ post, articleRef }: any) => (
  <section
    className={styles.screenBox}
    style={{
      backgroundImage: `url(${post.frontmatter.mainImage?.childImageSharp.fluid.src})`,
    }}
  >
    <div className={styles.textWrapper}>
      <h2>{post.frontmatter.title}</h2>
      <p className={styles.postExcerpt}>{post.excerpt}</p>
      <div
        onClick={() => {
          articleRef.current.scrollIntoView({ behavior: 'smooth' });
        }}
        className={styles.readMore}
      >
        READ MORE
      </div>
    </div>
    <div className={styles.whiteLine}></div>
    <div className={styles.whiteLine + ' ' + styles.whiteLineBottom}></div>
    <div className={styles.postInfo}>
      <div>
        <span className={styles.categoryNumber}>02</span>
        <span>{post.frontmatter.category}</span>
      </div>
      <div>
        <span className={styles.writtenBy}>
          written <br></br> by
        </span>
        <span>{post.frontmatter.author}</span>
      </div>
      <div>
        <span className={styles.publishedOn}>
          published <br></br> on
        </span>
        <span>{format(new Date(post.frontmatter.date), 'MMMM dd')}</span>
      </div>
    </div>
    <div className={styles.mobilePostInfo}>
      <div>
        <span className={styles.writtenBy}>written by &nbsp;</span>
        <span>{post.frontmatter.author}</span>
      </div>
      <div>
        <span className={styles.publishedOn}>published on &nbsp;</span>
        <span>{format(new Date(post.frontmatter.date), 'MMM. do. yyyy')}</span>
      </div>
    </div>
  </section>
);
